import fetch from '../utils/fetch'

export function getGiftCardGroupList (query) {
  return fetch({
    url: '/gift/card/group/list',
    method: 'get',
    params: query
  })
}

export function getGiftCardGroupInfo (query) {
  return fetch({
    url: '/gift/card/group/info',
    method: 'get',
    params: query
  })
}

export function createGiftCardGroup (params) {
  return fetch({
    url: '/gift/card/group',
    method: 'post',
    params: params
  })
}

export function changeGiftCardGroupState (group_id, params) {
  return fetch({
    url: '/gift/card/group/state/' + group_id,
    method: 'post',
    params: params
  })
}

export function changeGiftCardGroupTime (group_id, params) {
  return fetch({
    url: '/gift/card/group/time/' + group_id,
    method: 'post',
    params: params
  })
}

export function createGiftCardByGroup(group_id) {
  return fetch({
    url: '/gift/card/create/' + group_id,
    method: 'post',
  })
}



export function getGiftCardList (query) {
  return fetch({
    url: '/gift/card/list',
    method: 'get',
    params: query
  })
}

export function changeGiftCardState (card_id, params) {
  return fetch({
    url: '/gift/card/state/' + card_id,
    method: 'post',
    params: params
  })
}

export function changeGiftCardTime (card_id, params) {
  return fetch({
    url: '/gift/card/time/' + card_id,
    method: 'post',
    params: params
  })
}


export function getGiftCardLogList (query) {
  return fetch({
    url: '/gift/card/log/list',
    method: 'get',
    params: query
  })
}


export function getGiftCardRecordList (query) {
  return fetch({
    url: '/gift/card/record/list',
    method: 'get',
    params: query
  })
}